import React, { useEffect, useState } from 'react';
const SummaryPage = ({ asset, threat, control }) => {
    return (
      <div>
        <h2>Summary</h2>
        <h3>Asset: {asset.name}</h3>
        <p>{asset.description}</p>
        <h3>Threat: {threat.name}</h3>
        <p>{threat.description}</p>
        <h3>Control: {control.name}</h3>
        <p>{control.description}</p>
      </div>
    );
  };
  
  export default SummaryPage;
  