import React, { useState } from 'react';
import AssetCreation from '../trees/AssetCreation';
import ThreatCreation from '../trees/ThreatCreation';
import ControlCreation from '../trees/ControlCreation';
import SummaryPage from '../trees/SummaryPage';

const OnboardingFlow = () => {
  const [asset, setAsset] = useState(null);
  const [threat, setThreat] = useState(null);
  const [control, setControl] = useState(null);

  if (!asset) {
    return <AssetCreation onAssetCreated={setAsset} />;
  }
  
  if (!threat) {
    return <ThreatCreation asset={asset} onThreatCreated={setThreat} />;
  }

  if (!control) {
    return <ControlCreation threat={threat} onControlCreated={setControl} />;
  }

  return <SummaryPage asset={asset} threat={threat} control={control} />;
};

export default OnboardingFlow;
