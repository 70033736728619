import React, { useEffect, useState } from 'react';
const ThreatCreation = ({ asset, onThreatCreated }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
  
    const handleCreateThreat = () => {
      const newThreat = { name, description, assetId: asset.id };
      onThreatCreated(newThreat);  // Pass the threat to the next step
    };
  
    return (
      <div>
        <h2>What could go wrong with {asset.name}?</h2>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Threat Name"
        />
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Describe the threat"
        />
        <button onClick={handleCreateThreat}>Next</button>
      </div>
    );
  };
  
  export default ThreatCreation;
  