import Keycloak from 'keycloak-js';

class KeyCloakService {
  constructor() {
    this.authenticated = false;
    this.keycloak = new Keycloak({
      url: process.env.REACT_APP_IDENTITY_URL,
      realm: process.env.REACT_APP_IDENTITY_REALM,
      clientId: process.env.REACT_APP_IDENTITY_CLIENT,
    });
  }

  async init(onLoad = 'login-required', pkceMethod = 'S256') {
    try {
      const auth = await this.keycloak.init({ onLoad, pkceMethod });
      this.authenticated = auth;
      if (!auth) {
        window.location.reload();
      }
      return auth;
    } catch (err) {
      console.error('Authentication Failed', err);
      return false;
    }
  }

  login() {
    return this.keycloak.login();
  }

  logout() {
    return this.keycloak.logout();
  }

  getToken() {
    return this.keycloak.token;
  }

  isAuthenticated() {
    return this.authenticated;
  }

  getUserProfile() {
    return this.keycloak.loadUserProfile();
  }

  updateToken(minValidity = 5) {
    return this.keycloak.updateToken(minValidity);
  }

  isBannedOrSuspended = () => {
    const banned = this.keycloak.hasRealmRole('banned_users');
    const suspended = this.keycloak.hasRealmRole('suspended_users');
    return banned || suspended;
  }

  hasRealmRole(role) {
    return this.keycloak.hasRealmRole(role);
  }

  hasResourceRole(role, resource) {
    return this.keycloak.hasResourceRole(role, resource);
  }
}

const keycloakService = new KeyCloakService();
export default keycloakService;
