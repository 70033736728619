import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import MailIcon from '@mui/icons-material/Mail';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Badge from '@mui/material/Badge';
import api from '../../services/api';
const AdminTopBar = ({ token }) => {
  const [adminInfo, setAdminInfo] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchAdminInfo = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get('/v1/admin/bannerstats');
      if (response && response.data) {
        setAdminInfo(response.data.count); // We are only interested in the count for now
      } else {
        console.error('Unexpected API response structure', response);
        setError('Unexpected API response');
      }
    } catch (error) {
      console.error('Error fetching admin info', error);
      setError('Error fetching admin info');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdminInfo();
  }, [token]);

  return (
    <Box sx={{ position: 'fixed', top: 0, right: 0, zIndex: 1300, padding: '8px' }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.8)', // Subtle transparency
          boxShadow: 'none',
          borderRadius: '4px',
          maxWidth: '250px', // Smaller width to make it less intrusive
          marginRight: '8px',
        }}
      >
        <Toolbar variant="dense">
          <Tooltip title="Refresh Data">
            <IconButton
              color="inherit"
              onClick={fetchAdminInfo}
              disabled={loading}
              sx={{ marginRight: 2 }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : error ? (
            <Alert severity="error" sx={{ backgroundColor: 'transparent', padding: 0 }}>
              Error
            </Alert>
          ) : (
            <Badge badgeContent={adminInfo} color="secondary">
              <MailIcon color="inherit" data-testid="MailIcon"  /> {adminInfo}
            </Badge>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default AdminTopBar;
