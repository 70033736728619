import React, { useEffect, useState } from 'react';
const ControlCreation = ({ threat, onControlCreated }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
  
    const handleCreateControl = () => {
      const newControl = { name, description, threatId: threat.id };
      onControlCreated(newControl);  // Finish the onboarding flow
    };
  
    return (
      <div>
        <h2>How can you protect against {threat.name}?</h2>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Control Name"
        />
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Describe the control"
        />
        <button onClick={handleCreateControl}>Finish</button>
      </div>
    );
  };
  
  export default ControlCreation;  