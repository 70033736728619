import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import keycloakService from './utils/keycloak';
import { Mixpanel } from './utils/mixpanel';
import AdminTopBar from './components/admin/topbar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import BaseLayout from './components/base/BaseLayout';
import AttackTreesOverview from './components/trees/AttackTreesOverview';
import TreeDetails from './components/trees/TreeDetail';
import OnboardingFlow from './components/base/OnboardingFlow';
const theme = createTheme({
  palette: {
    primary: {
      main: '#e5592b', // Accent 1
    },
    secondary: {
      main: '#a64729', // Accent 2
    },
    background: {
      default: '#fcfcfc', // Background
    },
    text: {
      primary: '#282425', // Foreground
    },
  },
});

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAllowed, setIsAllowed] = useState(true);
  const [hasAdminRole, setHasAdminRole] = useState(false);
  const [token, setToken] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const initKeycloak = async () => {
      const auth = await keycloakService.init();
      setIsAuthenticated(auth);

      if (auth) {
        const isRestricted = keycloakService.isBannedOrSuspended();
        setIsAllowed(!isRestricted);
        const userProfile = await keycloakService.getUserProfile();
        setUserInfo(userProfile);

        // Track user login event
        Mixpanel.track('User Logged In', {
          distinct_id: userProfile.id,
        });

        if (!isRestricted) {
          const hasRole = keycloakService.hasRealmRole('riskforge_admin');
          setHasAdminRole(hasRole);

          // Track role information
          Mixpanel.people.set({
            distinct_id: userProfile.id,
            hasAdminRole: hasRole,
          });

          const token = keycloakService.getToken();
          setToken(token);

          // Track admin access
          if (hasRole) {
            Mixpanel.track('Admin TopBar Accessed', {
              distinct_id: userProfile.id,
            });
          }
        } else {
          keycloakService.logout();
          Mixpanel.track('User Restricted and Logged Out', {
            distinct_id: userProfile.id,
          });
        }
      }
    };

    initKeycloak();
  }, []);

  const handleLogout = () => {
    Mixpanel.track('User Logged Out', {
      distinct_id: userInfo?.id,
    });
    keycloakService.logout();
  };

  if (!isAuthenticated) {
    return <div>Loading...</div>;
  }

  if (!isAllowed) {
    return <div>You are not authorized to access this application at this time, please contact support@riskforge.co.</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <BaseLayout>
          <Routes>
            <Route path="/" element={<OnboardingFlow />} />
            <Route path="/attack-trees/:id" element={<TreeDetails />} />
          </Routes>
          {hasAdminRole && <AdminTopBar token={token} />}
          <h2>Is authenticated: {isAuthenticated.toString()}</h2>
          <h3>Has Admin Role: {hasAdminRole.toString()}</h3>
          <button onClick={handleLogout}>Logout</button>
        </BaseLayout>
      </Router>
    </ThemeProvider>
  );
};

export default App;
