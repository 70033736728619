import api from "./api";

export const getAttackTrees = async (userId) => {
    // This should call your backend API to fetch attack trees
    // For now, we'll mock the data
    return [
      {
        id: '1',
        name: 'Data Breach Scenario',
        description: 'Simulated attack on customer data.'
      },
      {
        id: '2',
        name: 'Ransomware Scenario',
        description: 'A model of potential ransomware attacks.'
      }
    ];
  };
export const getAttackTreeDetails = async (id) => {
    // You can add logic here to return different data based on the `id`, if necessary
    return {
      id: id,
      name: `Attack Tree ${id}`,
      description: `This is a detailed description of the attack tree with ID ${id}.`,
      nodes: [
        {
          id: 'node1',
          name: 'Initial Threat',
          riskLevel: 'High',
        },
        {
          id: 'node2',
          name: 'Mitigation Step 1',
          riskLevel: 'Medium',
        },
        {
          id: 'node3',
          name: 'Mitigation Step 2',
          riskLevel: 'Low',
        },
      ],
    };
  };