import mixpanel from 'mixpanel-browser';
console.log(process.env.REACT_APP_MIXPANEL_TOKEN);
mixpanel.init(`${process.env.REACT_APP_MIXPANEL_TOKEN}`);

// Move env_check inside each function to ensure it checks at runtime
let actions = {
  identify: (id) => {
    const env_check = process.env.NODE_ENV === 'production';
    if (env_check) mixpanel.identify(id);  // Removed outer check
  },
  alias: (id) => {
    const env_check = process.env.NODE_ENV === 'production';
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    const env_check = process.env.NODE_ENV === 'production';
    if (env_check) mixpanel.track(name, props);
  },
  register: (props) => {
    const env_check = process.env.NODE_ENV === 'production';
    if (env_check) mixpanel.register(props);
  },
  people: {
    set: (props) => {
      const env_check = process.env.NODE_ENV === 'production';
      if (env_check) mixpanel.people.set(props);
    },
    increment: (props) => {
      const env_check = process.env.NODE_ENV === 'production';
      if (env_check) mixpanel.people.increment(props);
    },
  },
};

export let Mixpanel = actions;
