import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Container } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Image } from '@mui/icons-material';

const BaseLayout = ({ children }) => {
  return (
    <div className="min-h-screen bg-background text-foreground">
      {/* Header */}
      <AppBar position="static" className="bg-accent1">
        <Toolbar>
          <IconButton edge="start" className="text-foreground" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className="text-foreground">
           <img src='/images/logo_black.png' height='30'/>
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Sidebar and Main Content */}
      <div className="flex">
        {/* Sidebar */}
        <aside className="w-64 bg-accent3 h-screen p-4">
          <nav>
            <ul>
              <li className="mb-4">
                <a href="#" className="text-foreground hover:text-accent1">
                  Dashboard
                </a>
              </li>
              <li className="mb-4">
                <a href="#" className="text-foreground hover:text-accent1">
                  Settings
                </a>
              </li>
              <li className="mb-4">
                <a href="#" className="text-foreground hover:text-accent1">
                  Analytics
                </a>
              </li>
            </ul>
          </nav>
        </aside>

        {/* Main Content */}
        <main className="flex-1 p-8">
          <Container maxWidth="lg">
            {children}
          </Container>
        </main>
      </div>
    </div>
  );
};

export default BaseLayout;
