import React, { useState } from 'react';
import {
  TextField, Button, Slider, Typography, Card, CardContent, Grid, Stepper, Step, StepLabel,
  Checkbox, FormControlLabel, Accordion, AccordionSummary, AccordionDetails, MenuItem
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NetworkIcon from '@mui/icons-material/NetworkCheck'; // Placeholder for visual work

const steps = ['Asset Type & Name', 'Connection Type', 'Sub-Assets & Risk Information', 'Additional Settings'];

const AssetCreation = ({ onAssetCreated }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [connectionType, setConnectionType] = useState('internet');
  const [userAccess, setUserAccess] = useState(true);
  const [adminAccess, setAdminAccess] = useState(true);
  const [importance, setImportance] = useState(50);
  const [riskExposure, setRiskExposure] = useState([]);
  const [boundaries, setBoundaries] = useState('');
  const [subAssets, setSubAssets] = useState([]);

  const assetTypes = [
    { value: 'network', label: 'Network', icon: <NetworkIcon /> }, // Placeholder for custom image
    { value: 'server', label: 'Server/System' },
    { value: 'web_app', label: 'Web Application' },
    { value: 'database', label: 'Database' },
    { value: 'device', label: 'Device/Endpoint' },
  ];

  const connectionOptions = [
    { value: 'internet', label: 'Internet' },
    { value: 'internal_network', label: 'Internal Network' },
    { value: 'vpn', label: 'VPN' },
  ];

  const riskOptions = [
    { value: 'financial', label: 'Financial Loss' },
    { value: 'reputation', label: 'Reputation Damage' },
    { value: 'legal', label: 'Legal Risk' },
    { value: 'data_loss', label: 'Data Loss' },
  ];

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      const newAsset = {
        name,
        type,
        connectionType,
        userAccess,
        adminAccess,
        importance,
        riskExposure,
        boundaries,
        subAssets, // Assets inside a network, if applicable
      };
      onAssetCreated(newAsset);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleRiskChange = (event) => {
    const value = event.target.value;
    setRiskExposure((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
    );
  };

  const addSubAsset = () => {
    setSubAssets([...subAssets, { name: '', type: '' }]);
  };

  const updateSubAsset = (index, field, value) => {
    const updatedSubAssets = [...subAssets];
    updatedSubAssets[index][field] = value;
    setSubAssets(updatedSubAssets);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="What's the name of what you're protecting?"
                variant="outlined"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="What kind of thing is this?"
                variant="outlined"
                fullWidth
                select
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                {assetTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.icon} {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body2">
                We assume this is connected to the <strong>{connectionType}</strong>. You can adjust this if needed.
              </Typography>
              <TextField
                label="How is it connected?"
                variant="outlined"
                fullWidth
                select
                value={connectionType}
                onChange={(e) => setConnectionType(e.target.value)}
              >
                {connectionOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        );
      case 2:
        if (type === 'network') {
          return (
            <Grid container spacing={3}>
              <Typography gutterBottom>What assets are inside this network?</Typography>
              {subAssets.map((subAsset, index) => (
                <Grid item xs={12} key={index}>
                  <TextField
                    label="Sub-Asset Name"
                    variant="outlined"
                    fullWidth
                    value={subAsset.name}
                    onChange={(e) => updateSubAsset(index, 'name', e.target.value)}
                  />
                  <TextField
                    label="Sub-Asset Type"
                    variant="outlined"
                    fullWidth
                    select
                    value={subAsset.type}
                    onChange={(e) => updateSubAsset(index, 'type', e.target.value)}
                  >
                    {assetTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              ))}
              <Button variant="outlined" onClick={addSubAsset}>
                Add Another Asset
              </Button>
            </Grid>
          );
        } else {
          return (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography gutterBottom>How important is this to your operations?</Typography>
                <Slider
                  value={importance}
                  onChange={(e, newValue) => setImportance(newValue)}
                  min={0}
                  max={100}
                  valueLabelDisplay="auto"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom>What risks does this face?</Typography>
                {riskOptions.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    control={
                      <Checkbox
                        checked={riskExposure.includes(option.value)}
                        onChange={handleRiskChange}
                        value={option.value}
                      />
                    }
                    label={option.label}
                  />
                ))}
              </Grid>
            </Grid>
          );
        }
      case 3:
        return (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Additional Settings (Optional)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                label="Who can access this?"
                variant="outlined"
                fullWidth
                value={boundaries}
                onChange={(e) => setBoundaries(e.target.value)}
                helperText="For example, only internal teams or external users"
              />
            </AccordionDetails>
          </Accordion>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Card className="bg-white shadow-lg p-6 max-w-2xl mx-auto mt-8">
      <CardContent>
        <Typography variant="h5" className="mb-4">Add What You Want to Protect</Typography>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className="mt-4">{getStepContent(activeStep)}</div>
        <div className="mt-6">
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className="mr-2"
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
          >
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default AssetCreation;
