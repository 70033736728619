import React, { useEffect, useState } from 'react';
import { Container, Typography, Card, CardContent } from '@mui/material';
import { useParams } from 'react-router-dom';
import { getAttackTreeDetails } from '../../services/attackTreeService';

const TreeDetails = () => {
  const { id } = useParams();  // Get the tree ID from the URL
  const [treeDetails, setTreeDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTreeDetails = async () => {
      const details = await getAttackTreeDetails(id);
      setTreeDetails(details);
      setLoading(false);
    };

    fetchTreeDetails();
  }, [id]);

  if (loading) {
    return <div>Loading tree details...</div>;
  }

  return (
    <Container>
      <Typography variant="h4">{treeDetails.name}</Typography>
      <Card>
        <CardContent>
          <Typography variant="body1">{treeDetails.description}</Typography>
          {/* You can expand this to show detailed information about nodes, controls, etc. */}
        </CardContent>
      </Card>
    </Container>
  );
};

export default TreeDetails;
